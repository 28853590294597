<template>

  <div :class="'fI mobile_' + isMobile()">

    <div id="loginWidget">
      <div class="pulsate loginWidgetText">
        <div>~ <translate>2FA SETUP</translate> ~</div>
      </div>
    </div>

    <!--LoginWidgets /-->

    <div style="overflow: hidden;">

      <FarmgodHeader>
        <div class='row' style="top: 10%; position: sticky;">
          <div class='col-sm-12'>
            <h1 id="logo_main" class="teaserLogoMain">
              <Logo v-bind:link="'none'" v-bind:title="'Powered by FarmGod.io'"/>
            </h1>
          </div>
        </div>
      </FarmgodHeader>

      <div>
        <span>
          <span v-translate>Scan this QR code in your OTP app. If you are not using any OTP app yet, check out Authy, FreeOTP, or Google Authenticator.</span><br/>
          <div style="margin-top: 15px;">
            <canvas id='qrcode'></canvas>
            <!-- <qrcode-vue v-if='otpSecret' type='number' :value="otpUrl" :size="110" level="H" /> -->
          </div>
          <small style="padding-top: 15px;color: #a7a7a7;">(or <span @click="copyOtpSecret" :title="$gettext('Cick to copy the secret key to your clipboard.')" style="cursor: pointer;"><b style="color: #d6d6d6;">copy</b></span> the secret key for manual entry)</small>
        </span>
        <div id='external-error' v-if='errors.root' class='landingPageInput item-errors alert alert-danger'>
          <translate>Otp setup authenticator error:</translate> {{ errors.root }}
        </div>
        <FormulateForm
          v-model='values'
          :errors='errors'
          #default="{ hasErrors }"
          @submit='completeOtpSetup()'
        >
          <div id="OtpMethodForm">
            <div class="logo_spacer_top"></div>
            <div class="loginFormWrapper form-group" style="margin: auto;">
              <span v-translate>Please enter the code <b>generated by your OTP app</b> to finish the setup.</span><br/><br/>
              <FormulateInput
                class="landingPageInput otpInput"
                name='final_otp'
                validation='bail|number|min:6,length'
                v-on:input='inputWatcher'
                type="text"
                autocomplete="off"
                maxlength="6"
                placeholder='. . . . . .'
                style="margin-bottom: 5px;"
                :validation-messages="{
                  required: 'Otp is required',
                  number: 'Otp must be a number',
                  min: 'Otp must be 6 digits long.'
                }"
              />
            </div>
            <div class="logo_spacer_bottom"></div>
            <div class="form-group">
              <button v-if='hasErrors' disabled class="landingPageButton btn btn-primary trans" style="opacity: 0.3; cursor: not-allowed;"><i class="fas fa-check fas-left"></i><translate>CONFIRM</translate></button>
              <button v-else class="landingPageButton btn btn-success" ><i class="fas fa-lock fas-left"></i><translate>CONFIRM</translate></button>
            </div>
          </div>

        </FormulateForm>
      </div>

    </div>

    <FarmgodFooter>
      <LoggedOutFooter />
    </FarmgodFooter>

    <div style='display: none' id='hidden_otp_url'>
      {{ otpUrl }}
    </div>
  </div>

</template>

<script>
import { mapGetters } from 'vuex'
import QRCode from 'qrcode'
import { OTP__REQUEST_SETUP, OTP__COMPLETE_SETUP } from '@/store/actions.type'
import FarmgodHeader from '@/components/layout/TheHeader'
import Logo from '@/components/layout/Logo'
import FarmgodFooter from '@/components/layout/TheFooter'
import LoggedOutFooter from '@/components/layout/Footers/LoggedOut'

export default {
  data () {
    return {
      email: (this.$route.query.email ? this.$route.query.email : null),
      values: {
        final_otp: null
      },
      errors: {}
    }
  },
  components: { FarmgodHeader, Logo, FarmgodFooter, LoggedOutFooter },
  computed: {
    ...mapGetters(['currentUser', 'otpSecret']),
    otpUrl () {
      return `otpauth://totp/${this.currentUser.email ? this.currentUser.email : this.email}?secret=${this.otpSecret}&issuer=Braiins%20OS%2B%20Manager`
    }
  },
  watch: {
    otpSecret: function (newOS, oldOS) {
      if (newOS !== null) {
        const comp = this
        QRCode.toCanvas(document.getElementById('qrcode'), this.otpUrl, function (error) {
          if (error) console.error(error)
          console.log('qrcode success!: ', comp.otpUrl)
        })
      }
    }
  },
  methods: {
    isMobile () {
      if (/Android|webOS|iPhone|iPod|iPad|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    async copyOtpSecret () {
      await navigator.clipboard.writeText(this.otpSecret)
    },
    inputWatcher () {
      if (this.values.final_otp && this.values.final_otp.length === 6) {
        this.completeOtpSetup()
      }
    },
    requestAppOTP () {
      this.$store.dispatch(OTP__REQUEST_SETUP, 'authenticator')
    },
    completeOtpSetup () {
      this.$store.dispatch(OTP__COMPLETE_SETUP, this.values.final_otp).then((response) => {
        this.$router.push({ name: 'otp__app', query: { verified_otp: this.values.final_otp } })
      }).catch(err => this.handleErrors(err))
    },
    handleErrors (err) {
      if (!err.response) {
        this.errors = { root: 'There was a server error, please check your connection' }
        return
      }

      const status = parseInt(err.response.status)
      const otpStatus = err.response.headers['x-2fa-status']

      if (status === 400) {
        if (otpStatus === '2FA_DISABLED_GLOBALLY') {
          this.errors = { root: 'Two factor authentication is disabled' }
        } else if (otpStatus === '2FA_GENERAL_ERROR') {
          this.errors = { root: 'Otp mode is invalid' }
        } else if (otpStatus === '2FA_INVALID_OTP') {
          this.errors = { final_otp: ['Otp is invalid'] }
        } else {
          this.errors = { root: err.response.data.detail }
        }
      } else if (status === 403) {
        console.log('403 FORBIDDEN!')

        if (otpStatus === '2FA_EXPIRED_TOKEN') {
          this.errors = { root: 'Failed to validate 2FA temporary token, either expired or or of invalid type (jti)' }
        } else if (otpStatus === '2FA_INVALID_STATE') {
          this.errors = { root: '2FA mismatch between login/2FA calls' }
        } else if (otpStatus === '2FA_SETUP_DONE') {
          this.errors = { root: '2FA setup already done' }
        } else if (otpStatus === '2FA_SETUP_FAILED') {
          this.errors = { root: '2FA setup failed on invalid OTP' }
        } else if (otpStatus === '2FA_SETUP_NOT_INITIALIZED') {
          this.errors = { root: '2FA setup not initialized' }
        } else {
          this.errors = { root: err.response.data.detail }
        }
      } else if (status === 404) {
        this.errors = { root: 'User not found' }
      } else if (status === 422) {
        const errorsObject = { final_otp: [] }
        err.response.data.detail
          .map((err) => {
            errorsObject[err.loc[1]].push(err.msg)
          })
        this.errors = errorsObject
      }
    }
  },
  created () {
    this.requestAppOTP()
  }
}
</script>
